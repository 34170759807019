export const HOSTNAME = window.location.hostname;

export const environment = {
  production: true,
  name: 'Prod',
  tenantId: 'DB',
  hostname: HOSTNAME,
  api: {
    hostname: HOSTNAME,
    docgen: `https://api.${HOSTNAME}/docgen/`,
    docComparison: `https://api.${HOSTNAME}/diff/`,
    o3: `https://api.${HOSTNAME}/users/`,
    productCapture: `https://api.${HOSTNAME}/lifecycle/`,
    schedule: `https://api.${HOSTNAME}/schedule/`,
    workflow: `https://api.${HOSTNAME}/workflow/`,
    staticData: `https://api.${HOSTNAME}/staticdata/`,
    analyticsService: `https://api.${HOSTNAME}/analytics/`,
    releaseManagement: `https://api.${HOSTNAME}/release/`,
    releaseManagementWss: `wss://rmb-wss.${HOSTNAME}/`,
    dataIngestion: `https://api.${HOSTNAME}/pipeline-runner/`,
    dataEnrichment: `https://api.${HOSTNAME}/di-staticdata/`,
    rulesProcessor: `https://api.${HOSTNAME}/rulesprocessor/`,
    productData: `https://api.${HOSTNAME}/product-data/`,
    regression: `https://api.${HOSTNAME}/regression/`,
    notification: `wss://ws.${HOSTNAME}/`,
    connectivity: `https://api.${HOSTNAME}/connectivity/`,
    ipb: `https://api.${HOSTNAME}/backend/`,
  },
  workflow: {
    api: {
      workflowEngine: `https://api.${HOSTNAME}/embeddedWorkflowEngine`
    },
    settings: {
      username: `admin`,
      password: `test`
    }
  }
};
