export const ROUTE_NAMES = {
  PRODUCT_CAPTURE: 'product-capture',
  LIFECYCLE_EVENTS: 'lifecycle-events',
  FEES_MANAGEMENT: 'fees-management',
  STATIC_DATA: 'static-data',
  RELEASE_MANAGEMENT: 'release-management',
  ADMIN: 'admin',
  ENRICHMENT: 'enrichment',
  INGESTION: 'ingestion',
  RULES: 'rules',
  VALIDATION: 'validation',
  NOT_FOUND: 'not-found',
  REGRESSION: 'regression',
  WORKFLOWS: 'workflows',
  SETTINGS: 'settings',
  CONNECTIVITY: 'connectivity',
  SALES: 'sales',
  ISSUANCE_WORKFLOW: 'issuance-workflow',
};
